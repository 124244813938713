import React, {useContext,useEffect} from 'react';
import AppHeader from './AppHeader';
import {ContentView} from '../../../index';
import Box from '@material-ui/core/Box';
import useStyles from './index.style';
import clsx from 'clsx';
import AppFooter from './AppFooter';
import AppFixedFooter from './AppFixedFooter';
import AppContext from '../../../utility/AppContext';
import AppSidebar from './AppSidebar';
import {LayoutType} from '../../../../shared/constants/AppEnums';
import {useDispatch, useSelector} from 'react-redux';
import {getRequestList} from '../../../../../src/redux/actions/index'
import moment from 'moment';
const HorDefault = (props) => {
  const {footer, layoutType, footerType} = useContext(AppContext);
  const classes = useStyles(props);
  const dispatch = useDispatch();
  const LoginUser = useSelector(({auth}) => auth);
  function formatDate(date) {
    if (moment(date)?.isValid() == true){
      var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();
    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;
    return [year, month, day].join('-');
    }
    else{
      return '';
    }
   
  }
  function updateScroll() {
    if ( Number(LoginUser?.user?.mrdDrCheck) == 3) {
      let getFromDate=localStorage.getItem('drFromDate');
      let getToDate=localStorage.getItem('drToDate');
      let passData = {
        userCode: LoginUser && LoginUser.user ? LoginUser.user.doctorCode : 0,
        fromDate: getFromDate ? formatDate(getFromDate) : '',
        toDate: getToDate ? formatDate(getToDate) : '',
        limit: 10,
        offset: 0,
      };
      dispatch(getRequestList(passData));
    }
  }

  useEffect(() => {
    if (
      Number(LoginUser?.user?.mrdDrCheck) == 3 
    ) {
      setInterval(updateScroll, 120000);
    }
  }, []);

  return (
    <Box
      className={clsx(
        classes.appMain,
        'appMainHor',
        layoutType === LayoutType.BOXED ? classes.boxedLayout : '',
        {
          appMainFooter: footer && footerType === 'fluid',
          appMainFixedFooter: footer && footerType === 'fixed',
        },
      )}>
      <AppHeader />
      <Box className={classes.mainContent}>
        <AppSidebar />
        <Box className={classes.mainContainer}>
          <ContentView />
          
        </Box> 
      </Box>
      
      <AppFooter />
      <AppFixedFooter />
    </Box>
  );
};

export default HorDefault;
