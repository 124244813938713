import React from 'react';

import {
    FETCH_ERROR,
    FETCH_START,
    FETCH_SUCCESS,
    SEARCH_MRD_RES,
    MRD_API_RES,
    GET_REQ_LIST,GET_REQ_COUNT,
    EXCEL_COMPLETE_DATA} from '../../shared/constants/ActionTypes';

import IntlMessages from '../../@crema/utility/IntlMessages';
import jwtAxios from '../../@crema/services/auth/jwt-auth/jwt-api';
import {fetchError, onWriteCloudWatchLog} from './Common';
//AWS cloud watch name
var streamname = 'Redux_Actions';
var filename = 'Mrdactions.js';

export const getSearchMrdlist = (value) => {
    return (dispatch) => {
      dispatch({type: FETCH_START});
      // Call doctor list Token  - Get Token
      try {
        jwtAxios
          .post('v1/mrdUser/getSearchMRDRecJwt', value)
          .then((data) => {
            try {
              let pdata={jwtToken:data.data.body['token']}
              jwtAxios
                .post('v1/mrdUser/getSearchMRDRec', pdata)
                .then((data) => {
                  if (data.status == 200) {
                    dispatch({type: FETCH_SUCCESS});
                    dispatch({
                      type: SEARCH_MRD_RES,
                      payload: data?.data?.body?.list ? data?.data?.body?.list:[],
                    });
                    if(data?.data?.body?.list?.length == 0 ){
                      if(data?.data?.body?.tag =='Pending Status'){
                        dispatch({
                          type: MRD_API_RES,
                          payload: 'The Episode number is already in your queue.',
                        });
                      }
                      else{
                        dispatch({
                          type: MRD_API_RES,
                          payload: 'No records found',
                        });
                      }
                    }
                  }
                })
                .catch((error) => {
                  dispatch({type: FETCH_ERROR, payload: error.message});
                  dispatch(
                    onWriteCloudWatchLog(
                      streamname,
                      filename,
                      'getSearchMrdlist',
                      '0',
                      error,
                    ),
                  );
                });
            } catch (err) {
              dispatch(fetchError(err.response.message));
              dispatch(
                onWriteCloudWatchLog(
                  streamname,
                  filename,
                  'getSearchMrdlist',
                  '0',
                  err,
                ),
              );
            }
          })
          .catch((error) => {
            dispatch({type: FETCH_ERROR, payload: error.message});
            dispatch(
              onWriteCloudWatchLog(
                streamname,
                filename,
                'getSearchMrdlist',
                '0',
                error,
              ),
            );
          });
      } catch (err) {
        dispatch(fetchError(err.response.message));
        dispatch(
          onWriteCloudWatchLog(streamname, filename, 'getSearchMrdlist', '0', err),
        );
      }
    };
  };

  export const submitMrdRequest = (value) => {
    return (dispatch) => {
      dispatch({type: FETCH_START});
        jwtAxios
          .post('v1/mrdUser/submitDrRequestJwt',value)
          .then((data) => {
            try {
              var passData = {jwtToken: data.data.body['token']};
              jwtAxios
                .post('v1/mrdUser/submitDrRequest', passData)
                .then((data) => {
                  dispatch({type: FETCH_SUCCESS});
                  dispatch({
                    type: SEARCH_MRD_RES,
                    payload: []
                  });
                  dispatch({
                    type: MRD_API_RES,
                    payload:'Submit Success',
                  });
                })
                .catch((error) => {
                  dispatch({type: FETCH_ERROR, payload: error.message});
                  dispatch(
                    onWriteCloudWatchLog(
                      streamname,
                      filename,
                      'submitMrdRequest',
                      '0',
                      error,
                    ),
                  );
                });
            } catch (err) {
              dispatch(fetchError(err.response.message));
              dispatch(
                onWriteCloudWatchLog(
                  streamname,
                  filename,
                  'submitMrdRequest',
                  '0',
                  err,
                ),
              );
            }
          })
          .catch((error) => {
            dispatch({type: FETCH_ERROR, payload: error.message});
            dispatch(
              onWriteCloudWatchLog(
                streamname,
                filename,
                'submitMrdRequest',
                '0',
                error,
              ),
            );
          });
      };
}

export const getRequestList = (value) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    // Call doctor list Token  - Get Token
    try {
      jwtAxios
        .post('v1/mrdUser/getDrPendingReqJwt', value)
        .then((data) => {
          try {
            let pdata={jwtToken:data.data.body['token']}
            jwtAxios
              .post('v1/mrdUser/getDrPendingReq', pdata)
              .then((data) => {
                if (data.status == 200) {
                  dispatch({type: FETCH_SUCCESS});
                  dispatch({
                    type: GET_REQ_LIST,
                    payload: data?.data?.body?.list ? data?.data?.body?.list :[],
                  });
                  dispatch({
                    type: GET_REQ_COUNT,
                    payload: data?.data?.body?.count ? data?.data?.body?.count :0,
                  });
                }
              })
              .catch((error) => {
                dispatch({type: FETCH_ERROR, payload: error.message});
                dispatch(
                  onWriteCloudWatchLog(
                    streamname,
                    filename,
                    'getSearchMrdlist',
                    '0',
                    error,
                  ),
                );
              });
          } catch (err) {
            dispatch(fetchError(err.response.message));
            dispatch(
              onWriteCloudWatchLog(
                streamname,
                filename,
                'getSearchMrdlist',
                '0',
                err,
              ),
            );
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'getSearchMrdlist',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(streamname, filename, 'getSearchMrdlist', '0', err),
      );
    }
  };
};

export const insertDrAction = (value) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    // Call doctor list Token  - Get Token
    try {
      jwtAxios
        .post('v1/mrdUser/insertDrActionLogJwt', value)
        .then((data) => {
          try {
            let pdata={jwtToken:data.data.body['token']}
            jwtAxios
              .post('v1/mrdUser/insertDrActionLog', pdata)
              .then((data) => {
                if (data.status == 200) {
                  dispatch({type: FETCH_SUCCESS});
                 
                }
              })
              .catch((error) => {
                dispatch({type: FETCH_ERROR, payload: error.message});
                dispatch(
                  onWriteCloudWatchLog(
                    streamname,
                    filename,
                    'insertDrAction',
                    '0',
                    error,
                  ),
                );
              });
          } catch (err) {
            dispatch(fetchError(err.response.message));
            dispatch(
              onWriteCloudWatchLog(
                streamname,
                filename,
                'insertDrAction',
                '0',
                err,
              ),
            );
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'insertDrAction',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(streamname, filename, 'insertDrAction', '0', err),
      );
    }
  };
};