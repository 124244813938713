/************
 * Developed by : Shiva Software Solutions
 * Date : 15-06-2021
 * Descriptions : Route configuration files
 * Dependicies : To use @material-ui/core
 ************/

import React from 'react';
import {Redirect} from 'react-router-dom';

import {createRoutes} from '../@crema/utility/Utils';
import {dashBoardConfigs} from './dashboard';
import {appointmentConfig} from './appointment';
import {authRouteConfig} from './auth';
import {initialUrl} from '../shared/constants/AppConst';
import {eRxDetailsConfigs} from './eRxDetails';
import {mrdTabConfigs} from './mrdRequestPage';
const routeConfigs = [
  ...authRouteConfig,
  ...dashBoardConfigs,
  ...eRxDetailsConfigs,
  ...appointmentConfig,
  ...mrdTabConfigs
];
const routes = [
  ...createRoutes(routeConfigs),
  {
    path: '/',
    exact: true,
    component: () => <Redirect to={initialUrl} />,
  },
  {
    component: () => <Redirect to='/error-pages/error-401' />,
  },
];

export default routes;
