import React from 'react';
import {authRole} from '../../shared/constants/AppConst';
//Route config for eRx details
export const mrdTabConfigs = [
  {
    auth: authRole.mrdName,
    routes: [
      {
        path: '/mrdRequest',
        component: React.lazy(() => import('./Requestpage')),
      },
    ],
  },
];